<template>
  <div id="app">
    <Snackbar></Snackbar>
    <CookiesPopup></CookiesPopup>
    <router-view />
    <v-idle v-show="false" @idle="logout" :loop="true" :duration="1800" />
  </div>
</template>

<script>
import Vue from "vue";
import Snackbar from "@/components/Snackbar.vue";
import CookiesPopup from "@/components/CookiesPopup.vue";
import 'material-symbols';
import Vidle from "v-idle";
Vue.use(Vidle);

import VueCookies from "vue-cookies";
Vue.use(VueCookies);

Vue.$cookies.config("1d");

export default {
  data() {
    return {};
  },
  components: { Snackbar, CookiesPopup },
  watch: {
    $route(to) {
      this.$eventHub.$emit("navbar-to", to.path);
      // this.getSelf();
    }
  },
  beforeCreate() {
    this.$store.commit("initialiseStore");
  },
  created() {
    this.$eventHub.$on("loged", () => this.getSelf());

    Vue.prototype.$usuario = {
      rol: {}
    };
  },
  mounted() {
    this.getSelf();
    console.log("01-02-2023");
  },
  methods: {
    getSelf() {
      let token = this.$cookies.get("token-landing");
      if (token == null) {
        token = localStorage.getItem("token-landing");
      }
      const that = this;
      this.$axios
        .get(
          this.$localurl +
            "/api/usuario/self" +
            (token != null ? "/" + token : "")
        )
        .then(function(response) {
          if (response.data != "") {
            Vue.prototype.$usuario = response.data;
            that.$forceUpdate();
            that.$eventHub.$emit("update");
          }
        })
        .catch(function() {
          // handle error
          if (
            window.location.pathname != "/login" &&
            window.location.pathname.split("/")[1] != "password" &&
            window.location.pathname.split("/")[3] != "password"
          ) {
            that.$router.push("/login");
          }
        });
    },
    logout() {
      localStorage.removeItem("token-landing");
      this.$store.commit("setReemplazante", null);
      localStorage.removeItem("reemplazo-id");
      this.$cookies.remove("token-landing");
      const that = this;
      this.$axios
        .get(this.$localurl + "/perform_logout")
        .then(function() {
          localStorage.removeItem("clientesVinculados");
          Vue.prototype.$usuario = {
            rol: { rol: "" }
          };
          that.$router.push("/login");
        })
        .catch(function(error) {
          // handle error
          alert(error);
        });
    }
  }
};
</script>

<style src="@/assets/css/fonts.css"></style>
<style src="@/assets/css/transitions.css"></style>
<style src="@/assets/css/app.css"></style>
<style src="@/assets/css/error.css"></style>
